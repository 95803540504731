.button {
	width: 100%;
	color: var(--palette-live-blue);
	font-size: var(--font-size-18);
	padding: 14px 0 14px 0;
	border: 1px solid;
	border-radius: 12px;
	font-weight: var(--font-weight-medium);
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center ;

	&-icon-loading {
		stroke: var(--palette-live-bg-0-white);
		height: 22px;
	}
}