.round-robin-short {
	display: flex;
	margin: 10px 16px 20px 16px;
	border-left: 1px solid var(--palette-live-grey-6);
	width: max-content;
	background-color: var(--palette-live-bg-0-white);
	box-sizing: border-box;
	border-top-left-radius: 11px;

	p {
		margin: 0;
	}

	&__separator {
		width: 5px;
		min-height: 100%;
		background-color: var(--palette-live-grey-8);
		border-right: 1px solid var(--palette-live-grey-6);
		box-sizing: border-box;
	}

	&__column {
		min-width: 30px;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		border-right: 1px solid var(--palette-live-grey-6);
		border-top: 1px solid var(--palette-live-grey-6);
		border-bottom: 1px solid var(--palette-live-grey-6);

		&:first-child {
			border-top-left-radius: 11px;
		}

		.round-robin-short__title {
			box-sizing: border-box;
			display: flex;
			align-items: center;
			padding: 0 15px;
			font-size: var(--font-size-22);
			min-height: 40px;
			font-weight: 600;
			color: var(--palette-live-grey-0);
			border-bottom: 1px solid var(--palette-live-grey-6);

			svg {
				margin-left: 10px;
			}
		}

		&:last-child {
			border-right: none;
			background-color: var(--palette-live-grey-0);
			border-top: 1px solid var(--palette-live-grey-0);
			border-bottom: 1px solid var(--palette-live-grey-0);

			.round-robin-short__title {
				font-size: var(--font-size-17);
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 0 10px;
				height: 40px;
				display: flex;
				align-items: center;
				font-weight: 500;
				color: var(--palette-live-bg-0-white);
			}
		}

		.round-robin-short__singles {
			&-item {
				position: relative;
				padding: 15px 10px 0 15px;
				font-size: var(--font-size-17);
				min-height: 20px;
				display: flex;
				align-items: center;
			}

			&-item:last-child {
				padding: 15px 10px 13px 15px;
			}
		}

		.round-robin-short__doubles-item {
			padding: 15px 10px 13px 15px;
			position: relative;
			min-height: 20px;

			.tooltip-container {
				display: block;
			}

			.round-robin-short__doubles-name {
				font-size: var(--font-size-17);
				margin-bottom: 2px;
				margin-top: 2px;

				.empty-player{
					margin: 5px 0 20px 0;
				}
			}
		}

		.empty-player {
			display: block;
		}

		.round-robin-short__order {
			position: absolute;
			top: 3px;
			left: 3px;
			color: var(--palette-live-grey-7);
			font-size: var(--font-size-14);
		}

		.round-robin-short__match {
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: var(--font-size-17);
			position: relative;

			.round-robin-short__match-ball {
				transform: scale(0.9);
				border-radius: 100%;
				position: absolute;
				top: 2px;
				left: 2px;
			}
		}

		&.result-column {
			max-width: 30px;

			.round-robin-short__result {
				box-sizing: border-box;
				flex: 1;
				border-bottom: 1px solid var(--palette-live-grey-6);
				display: flex;
				align-items: center;
				justify-content: center;
				color: var(--palette-live-blue-2);
				font-size: 17px;
				width: 30px;

				&:last-child {
					border-bottom: none;
				}
			}
		}
	}
}