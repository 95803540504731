.stage-build-group {
	border-radius: 16px;
	border: 1px solid var(--palette-live-grey-7);

	&:last-child {
		margin: 0 0 0 0;
	}

	&__header {
		display: flex;
		justify-content: space-between;
		padding: 0 14px;

		&--border {
			border-bottom: 1px solid var(--palette-live-grey-11);
		}

		.stage-build-group__name-wrapper {
			display: flex;

			.stage-build-group__header-name-group {
				color: var(--palette-live-grey-0);
				font-size: var(--font-size-22);
				padding: 18px 0;
				margin: 0 16px 0 0;
				font-weight: var(--font-weight-semi-bold);
			}
		}

		.group__header-wrapper {
			display: flex;
			align-items: center;

			.stage-build-group__icon-container {
				height: 100%;
				display: flex;
				align-items: center;
				cursor: pointer;
			}

			.stage-build-group__header-counter {
				color: var(--palette-live-grey-0);
				font-size: var(--font-size-22);
				margin: 0 8px 0 8px;
				font-weight: var(--font-weight-semi-bold);
			}
		}
	}
}