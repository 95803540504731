.tournament-grids__container {
	overflow: auto;
	flex: 1;
	display: flex;
	flex-direction: column;
	padding-top: 10px;

	&.filled {
		background-color: var(--palette-live-grey-12);
	}

	.tournament-grids {
		min-height: max-content;
		flex: 1;

		&__level {
			color: var(--palette-live-grey-2);
			font-size: var(--font-size-21);
			font-weight: var(--font-weight-medium);
			margin: 10px 15px 10px 15px;
		}

		&__nogroups {
			width: 100wv;
			box-sizing: border-box;
			margin: 20px;
			padding: 20px;
			height: 60px;
			border: 1px solid var(--palette-live-grey-6);
			border-radius: 11px;
			display: flex;
			align-items: center;
			background-color: var(--palette-live-bg-0-white);

			p {
				font-size: 20px;
				font-weight: 400;
			}
		}
	}

	.remove-stage-button {
		margin-top: 20px;
	}
}