.tournament-info__wrapper {
  padding: 0 16px 0 16px;
  flex: 1;
  overflow: auto;
}

.tournament-info__container{
  padding-bottom: 60px;
}

.tournament-info__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 15px 0;

  p {
    margin: 0;
  }
}

.tournament-info__header-title {
  font-size: var(--font-size-20);
  font-weight: 600;
}

.tournament-info__header-edition {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.form-description__container {
  border: 1px solid var(--palette-live-grey-6);
  border-radius: 16px;
  padding: 0 15px;
  margin-bottom: 15px;
}

.form-description__text,
.form-description__bold {
  color: #3A3A3A;
  font-size: var(--font-size-19);
  margin: 0 0 20px 0;
}

.form-description__subtitle {
  font-weight: 600;
}

.tournamet-info__tournament-delition {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 20px 0 0 0;

  .string-button {
    margin: 20px 0;
  }
}

.tournament-info__confirmation {
  font-size: var(--font-size-17);
  font-weight: 600;
  text-align: center;
  margin: 20px;
}

.tournament-info__confirmation-buttons {
  display: flex;
  justify-content: space-between;
  margin: 16px 16px 0 16px;
}

.tournament-info__confirmation-buttons button {
  margin: 0;
  width: 50%;
}

.tournament-info__confirmation-buttons button:first-child {
  margin-right: 5px;
}

.tournament-info__confirmation-buttons button:last-child {
  margin-left: 5px;
}