.venues {
	overflow: auto;
	margin: 0 16px 0 16px;
	flex: 1;

	&__name-container {
		display: flex;

		.venues__number {
			font-size: var(--font-size-20);
			color: var(--palette-live-grey-0);
			font-weight: var(--font-weight-regular);
			margin: 0 16px 0 0;
		}

		.venues__name {
			display: inline-block;
			font-size: var(--font-size-18);
			color: var(--palette-live-grey-0);
			font-weight: var(--font-weight-regular);
			margin: 0;
			width: calc(100vw - 100px);
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
	}
}