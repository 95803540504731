.switcher {
	display: flex;
	align-items: center;
	justify-content: space-between;

	.switch {
		position: relative;
		display: inline-block;
		min-width: 50px;
		height: 30px;

		& input {
			opacity: 0;
			width: 0;
			height: 0;
		}

		.switcher-slider {
			position: absolute;
			cursor: pointer;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: #ccc;
			transition: .4s;
			border-radius: 15px;

			&:before {
				position: absolute;
				content: "";
				height: 22px;
				width: 22px;
				border-radius: 50%;
				left: 4px;
				bottom: 4px;
				background-color: white;
				transition: .4s;
			}
		}

		input:checked + .switcher-slider {
			background-color: #007aff;
		}

		input:checked + .switcher-slider:before {
			transform: translateX(20px);
		}
	}

	.switcher__label {
		font-size: 18px;
		color: var(--palette-live-grey-0);
		margin: 0 16px 0 0;
	}
}