.addition-players-bylist {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	margin: 0 16px;
	height: calc(100dvh - 200px);

	&__inputs{
		width: 100%;
		
		.input-light {
			max-height: 44px;
		}
	}

	.rounded-button {
		margin-top: 20px;
	}
}