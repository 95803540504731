.textarea__container {
	margin: 0 0 16px 0;

	.textarea__block {
		margin: 0 0 0 0;
		border: 1px solid var(--palette-live-grey-6);
		border-radius: 10px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 16px 0 0;
    height: 150px;

		.textarea__wrapper {
			width: 100%;
			height: 72px;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: space-between;

			.textarea__element {
				width: 100%;
				height: 150px;
				color: var(--palette-live-grey-1);
				font-size: var(--font-size-18);
				margin: 0 0 0 0;
				padding: 40px 0 0 16px;
				border: 0;
				box-sizing: border-box;
				outline: none;
				border-top-left-radius: 9px;
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
				border-bottom-left-radius: 9px;
        resize: none;

				&--focus {
					-webkit-box-shadow: 0 0 0 100px var(--palette-live-bg-0-white) inset !important;
				}
			}

			.textarea-label {
				position: absolute;
				pointer-events: none;
				left: 16px;
				top: -20px;
				font-size: var(--font-size-18);
				white-space: nowrap;
				overflow: hidden;
				transition: .3s;
				color: var(--palette-live-grey-3);
			}

			.textarea__element:focus~.textarea-label,
			.textarea__element:not(:focus):not(:empty)~.textarea-label,
			.textarea__element:autofill~.textarea-label {
				font-size: var(--font-size-16);
			}
		}

		&--focus {
			background-color: var(--palette-live-bg-0-white);
			border-color: var(--palette-live-grey-1);
		}
	}

}