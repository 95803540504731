.stage-build {
	flex: 1;

	.double-button.fixed {
		bottom: 165px;
	}

	.stage-build__level {
		transition: opacity 0.2s ease-in-out;
		margin: 0 16px 0 16px;

		&:first-child {
			.stage-build__level-wrapper {
				display: flex;
				align-items: center;
				margin: 15px 0;
			}
		}

		&.level-margin {
			margin-top: 15px;
		}

		&-wrapper {
			display: flex;
			justify-content: space-between;
			margin: 25px 0 15px 0;

			.stage-build__level-name {
				color: var(--palette-live-grey-0);
				font-size: var(--font-size-18);
				margin: 0 0 0 0;
				font-weight: var(--font-weight-bold);
			}

			.delete-icon {
				width: 24px;
				height: 24px;
				fill: var(--palette-live-grey-6);
				cursor: pointer;
			}
		}

		&.remove {
			opacity: 0;
		}

		.stage-build-group__container {
			margin: 0 0 8px 0;
			transition: opacity 0.2s ease-in-out;

			&.remove {
				opacity: 0;
			}
		}
	}

	.stage-build__buttons-wrapper {
		display: flex;
		justify-content: space-between;
		margin: 16px 0 10px 0;
		flex-wrap: wrap;
		margin: 16px 16px 16px 16px;

		svg,
		span {
			cursor: pointer;
		}

		button:first-child {
			margin: 0 8px 0 0;
		}

		@media (max-width: 313px) {
			button:first-child {
				margin: 0 8px 8px 0;
			}
		}
	}

	&.reorder .stage-build-group__container,
	&.reorder .stage-build__buttons-wrapper {
		animation: moveUp 0.3s ease-in-out;
	}

	@keyframes moveUp {
		from {
			transform: translateY(5px);
		}

		to {
			transform: translateY(0);
		}
	}
}