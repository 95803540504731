.string-button {
	width: 100%;
	margin: 0 0 0 0;
	font-size: var(--font-size-19);
	color: var(--palette-live-grey-7);
	text-align: center;
	font-weight: var(--font-weight-medium);
	background-color: transparent;
	border: none;
	cursor: pointer;
	padding: 0;
}