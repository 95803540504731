.icon-button {
	display: flex;
	background-color: transparent;
	margin: 0 0 0 0;
	padding: 0 0 0 0;
	box-sizing: border-box;
	border: 0;
	align-items: center;
	flex: 1;
	justify-content: flex-start;

	&__title {
		color: var(--palette-live-grey-0);
		font-size: var(--font-size-22);
		margin: 0 0 0 8px;
		font-weight: var(--font-weight-semi-bold);
		height: 100%;
		display: flex;
		align-items: center;
	}
}