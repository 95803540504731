.change-group__wrapper {
	display: flex;
	justify-content: space-between;
	margin: 16px;
	flex-wrap: wrap;
	user-select: none;

	.change-group-type__wrapper {
		cursor: pointer;
		display: flex;
		align-items: center;
		border: 1px solid var(--palette-live-grey-7);
		border-radius: 100px;
		padding: 10px 16px 10px 16px;
		margin-right: 8px;
		background-color: transparent;
		flex: 1;

		@media screen and (max-width: 378px) {
			& {
				margin: 0 8px 8px 0;
			}
		}

		.change-group-type__text {
			color: var(--palette-live-grey-7);
			font-size: var(--font-size-18);
			margin: 0 16px 0 16px;
			font-weight: var(--font-weight-regular);
			flex: 1;
		}

		.arrow-down__icon {
			fill: var(--palette-live-grey-7);

			.arrow--up {

			}

			.arrow--down {

			}
		}
	}

	.counter-groupe__wrapper {
		display: flex;
		align-items: center;
		border: 1px solid var(--palette-live-grey-7);
		border-radius: 100px;
		padding: 10px 16px 10px 16px;

		.counter-groupe__button {
			padding: 0 0 0 0;
			margin: 0 0 0 0;
			border: 0;
			border-radius: 0;
			background-color: var(--palette-live-bg-0-white);
			cursor: pointer;
			display: flex;

			.remove-icon {
				width: 24px;
				height: 24px;
				fill: var(--palette-live-grey-7);
				flex-shrink: 0;
			}

			.plus-white__icon {
				width: 19px;
				height: 19px;
				fill: var(--palette-live-grey-7);
				flex-shrink: 0;
			}
		}

		.counter-groupe__text {
			color: var(--palette-live-grey-7);
			font-size: var(--font-size-22);
			margin: 0 16px 0 16px;
			font-weight: var(--font-weight-semi-bold);
		}
	}
}

.delete-group__wrapper {
	cursor: pointer;
	width: 100%;
	color: var(--palette-live-grey-7);
	font-size: var(--font-size-18);
	margin: 0 16px 16px 16px;
	padding: 0 0 0 0;
	font-weight: var(--font-weight-regular);
	background-color: transparent;
	border: 0;
	display: flex;
	box-sizing: border-box;
	align-items: center;

	.bucket__icon {
		width: 20px;
		height: 20px;
		fill: var(--palette-live-grey-7);
		margin: 0 16px 0 0;
	}
}

.group-players__popup-buttons {
	display: flex;
	margin: 16px 16px 0 16px;
}