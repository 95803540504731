.sort-modal {
	.popup__wrapper {
		max-width: 300px;

		.sort-modal-form {
			padding: 0 20px;

			.radio-button-light {
				border-bottom: 1px solid var(--palette-live-grey-5);
				padding-bottom: 10px;
				margin-bottom: 16px;
			}

			.radio-button-light:nth-last-child(2) {
				margin: 0;
				border-bottom: none;
			}

			.sort-modal__manual-field {
				display: flex;
				justify-content: space-between;
				align-items: flex-end;

				.input-light {
					max-width: 120px;
					margin: 0 16px 0 auto;

					&.error {
						.input__wrapper {
						border-bottom: 1px solid var(--palette-live-red-1);
						}
					}

					.input__wrapper {
						align-items: flex-end;
						padding: 0;

						.input__label {
							left: 10px;
						}

						svg {
							padding-bottom: 10px;
						}
					}
				}

				.rounded-button {
					max-width: 100px;
					height: 33px;
					font-size: 16px;
					padding: 0 !important;
				}
			}

			.sort-modal-buttons {
				.rounded-button {
					margin-top: 16px;
				}
			}
		}
	}
}