.tournament-form {
	overflow: auto;
	padding: 0 16px;

	.tournament-form__wrapper {
		.radio-button-light {
			margin-bottom: 10px;
		}

		.tournament-form__types {
			border-bottom: 1px solid var(--palette-live-grey-6);
		}

		.switcher {
			border-bottom: 1px solid var(--palette-live-grey-6);
			padding-bottom: 10px;
			margin-bottom: 10px;
		}

		.textarea__container {
			margin-top: 16px;
		}

		.tournament-form__description {
			color: var(--palette-live-grey-2);
			font-size: var(--font-size-18);
			margin: 0 0 16px 0;
			font-weight: var(--font-weight-regular);
		}

		.tournament-form__button {
			margin: 20px 0 20px 0;
		}
	}

	.popup__wrapper {
		.popup__title{
			flex: 1;
			align-items: center;
		}
	}

	.tournament-form__popup-message {
		margin: 0 20px 20px 20px;
		font-size: 18px;
	}
}