.tooltip-container {
  display: inline-block;
  position: relative;
}

.tooltip-box {
  position: absolute;
  background-color: var(--palette-live-grey-1);
  color: var(--palette-live-bg-0-white);
  padding: 6px 10px;
  border-radius: 6px;
	max-width: 200px;
  white-space: wrap;
  z-index: 1000;
  opacity: 0.9;
  font-size: var(--font-size-16);
	box-sizing: border-box;
	display: flex;
	align-items: center;

	div {
		overflow: hidden;
		text-overflow: ellipsis;
		box-sizing: border-box;
	}

	&::before {
		content: '';
		position: absolute;
		border-style: solid;
		display: block;
	}
}

.tooltip-top {
	&::before {
		top: 100%;
		left: 50%;
		border-width: 6px 6px 0 6px;
		border-color: var(--palette-live-grey-1) transparent transparent transparent;
	}
}

.tooltip-right {
	&::before {
		top: 50%;
		left: 0;
		border-width: 6px 6px 6px 0;
		border-color: transparent var(--palette-live-grey-1) transparent transparent;
	}
}

.tooltip-bottom {
	&::before {
		bottom: 100%;
		left: 14px;
		border-width: 0 6px 6px 6px;
		border-color: transparent transparent var(--palette-live-grey-1) transparent;
	}
}

.tooltip-left {
	&::before {
		top: 50%;
		right: 0;
		border-width: 6px 0 6px 6px;
		border-color: transparent transparent transparent var(--palette-live-grey-1);
	}
}
