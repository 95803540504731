.venus-form {
	overflow: auto;
	margin: 0 16px 0 16px;
	flex: 1;

	&__wrapper {
		margin: 16px 0 0 0;

		.venus-form__button {
			margin: 0 0 16px 0;
		}
	}

	&__delete-button {
		margin: 40px 0 16px 0;
	}
}