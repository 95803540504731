/* Подключаем файл обнуляющий css по умолчанию */
@import url("./normalize.css");

/* Задаём шрифт */
@font-face {
	font-family: "Roboto-Flex";
	src: url("./fonts/RobotoFlex-VariableFont_GRAD,XTRA,YOPQ,YTAS,YTDE,YTFI,YTLC,YTUC,opsz,slnt,wdth,wght.ttf");
}

@font-face {
	font-family: "Sawarabi Gothic";
	src: url("./fonts/SawarabiGothic-Regular_1.woff");
}

/* Переменные */
:root {
	// Color
	--palette-live-bg-3: #F5F7FA;
	--palette-live-red-1: #B00020;
	--palette-live-red-2: #EE856A;
	--palette-live-red-3: #CC684D;
	--palette-live-red-4: #ffe2e2;
	--palette-live-bg-1-white-old: #FAFBFC;
	--palette-live-bg-1-white: #FDFDFD;
	--palette-live-bg-0-white: #fff;
	--palette-live-blue: #0085F4;
	--palette-live-blue-2: #50AFFF;
	--palette-live-blue-3: #ebf4ff;
	--palette-live-grey-0: #2D2F30;
	--palette-live-grey-1: #474F5A;
	--palette-live-grey-2: #424547;
	--palette-live-grey-3: #C0C5CC;
	--palette-live-grey-4: #DDE3EB;
	--palette-live-grey-5: #E1E4E5;
	--palette-live-grey-6: #C9CDD1;
	--palette-live-grey-7: #A1A6AB;
	--palette-live-grey-8: #F2F5F7;
	--palette-live-grey-9: #92a0b4;
	--palette-live-grey-10: #e6e9ed;
	--palette-live-grey-11: #E6EBEE;
	--palette-live-grey-12: #F2F5F9;
	--palette-live-grey-13: #ddecff;
	--palette-live-grey-14: #707070;
	--palette-live-grey-15: #F7F9FA;
	--palette-live-grey-16: #e7e7e7;
	--palette-live-light-green: #CDFF40;
	--pale-silver: #E8EAEB;

	// Font Size
	--font-size-40: 40px;
	--font-size-35: 35px;
	--font-size-32: 32px;
	--font-size-30: 30px;
	--font-size-24: 24px;
	--font-size-23: 23px;
	--font-size-22: 22px;
	--font-size-21: 21px;
	--font-size-20: 20px;
	--font-size-19: 19px;
	--font-size-18: 18px;
	--font-size-17: 17px;
	--font-size-16: 16px;
	--font-size-15: 15px;
	--font-size-14: 14px;
	--font-size-12: 12px;
	--font-size-11: 11px;

	// Font Weight
	--font-weight-thin: 100;
	--font-weight-extra-light: 200;
	--font-weight-light: 300;
	--font-weight-regular: 400;
	--font-weight-medium: 500;
	--font-weight-semi-bold: 600;
	--font-weight-bold: 700;
	--font-weight-extra-bold: 800;
	--font-weight-black: 900;
}

body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: 'Roboto-Flex', sans-serif;

	.version-app__container {
		color: #575757;
		font-size: 10px;
		margin: 2px;
		z-index: 99999;
		font-weight: 400;
		position: absolute;
		top: 0;
		right: 0;
	}

	.arrow--down {
		cursor: pointer;
		transform: rotate(0deg);
		fill: var(--palette-live-grey-2);
	}

	.arrow--up {
		cursor: pointer;
		transform: rotate(180deg);
		fill: var(--palette-live-grey-2);
	}

	.empty-player {
		box-sizing: border-box;
		line-height: 0;
		min-width: 50px;
		height: 1.5px;
		background-color: var(--palette-live-grey-9);
	}

	input,
	textarea,
	button,
	select,
	a {
		-webkit-tap-highlight-color: transparent;
		text-decoration: none;
		cursor: pointer;
	}

	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	input:-webkit-autofill:active {
		-webkit-box-shadow: 0 0 0 100px var(--palette-live-bg-3) inset !important;
	}

	input[type="date"]::-webkit-calendar-picker-indicator {
		display: none;
	}

	input[type="number"]::-webkit-outer-spin-button,
	input[type="number"]::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}

	input[type='number'],
	input[type="number"]:hover,
	input[type="number"]:focus {
		appearance: none;
		-moz-appearance: textfield;
	}
}