.select {
	width: 100%;
	height: 56px;
	border-bottom: 1px solid var(--palette-live-grey-6);
	display: flex;
	justify-content: space-between;
	position: relative;
	cursor: pointer;

	&:hover {
		border-bottom: 1px solid var(--palette-live-grey-1);
	}

	.select-label {
		position: absolute;
		pointer-events: none;
		left: 16px;
		bottom: 17px;
		font-size: var(--font-size-18);
		white-space: nowrap;
		overflow: hidden;
		transition: .3s;
		color: var(--palette-live-grey-7);
		z-index: 1;
	}

	.select-input {
		height: 40px;
		margin-top: 14px;
		padding: 0 16px;
		display: block;
		border: none;
		width: 100%;
		box-sizing: border-box;
		cursor: pointer;
		outline: none;
		font-size: var(--font-size-18);
		color: var(--palette-live-grey-1);
	}

	.select-options {
		display: none;
		position: absolute;
		box-sizing: border-box;
		width: 100%;
		top: 101%;
		z-index: 10;
		background-color: var(--palette-live-bg-0-white);
		flex-direction: column;

		&.open {
			display: flex;
		}

		&__item {
			box-sizing: border-box;
			width: 100%;
			height: 48px;
			display: flex;
			align-items: center;
			padding: 0 10px;
			border-bottom: 1px solid var(--palette-live-grey-5);
			cursor: pointer;
			font-size: var(--font-size-18);
			word-wrap: break-word;
			background-color: var(--palette-live-grey-15);

			&:hover {
				background-color: var(--palette-live-blue-3);
			}

			&.active {
				background-color: var(--palette-live-blue-3);
			}
		}

		&_item:last-child() {
			border-bottom: none;
		}
	}
}