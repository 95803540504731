.arrow-up-down {
	.down {
		cursor: pointer;
		transform: rotate(0deg);
		fill: var(--palette-live-grey-2);
	}

	.up {
		cursor: pointer;
		transform: rotate(180deg);
		fill: var(--palette-live-grey-2);
	}
}